
<template>
  <el-container :class="{ 'dark-theme': isDark }" style="min-height: 100vh; background: var(--background); transition: background 0.3s;">
    <!-- 页眉 -->
    <el-header>
      <div class="header-content">
        <el-avatar src="https://trystage.win/img.png" />
        <el-text class="mx-1" type="primary" size="large">TryStage 玩家信息查询</el-text>
        <el-switch
          v-model="isDark"
          active-action-icon="Sunny"
          inactive-action-icon="Moon"
          @change="toggleTheme"
          active-color="#ffd04b"
          inactive-color="#409EFF"
        >
          <template #prefix>
            <el-icon class="el-switch__icon"><Sunny /></el-icon>
          </template>
          <template #suffix>
            <el-icon class="el-switch__icon"><Moon /></el-icon>
          </template>
        </el-switch>
      </div>
    </el-header>

    <!-- 主体内容 -->
    <el-main>
      <!-- 搜索框 -->
      <el-row justify="center" style="margin-top: 40px;">
        <el-col :xs="24" :sm="20" :md="16" :lg="12">
          <el-card class="search-card" shadow="hover">
            <el-row :gutter="20" align="middle">
              <el-col :span="18">
                <el-input
                  v-model="playerName"
                  placeholder="请输入玩家名称"
                  @keyup.enter="fetchPlayerData"
                  clearable
                >
                  <template #prefix>
                    <el-icon class="el-input__icon"><search /></el-icon>
                  </template>
                </el-input>
              </el-col>
              <el-col :span="6">
                <el-button type="primary" @click="fetchPlayerData" :loading="loading" block>
                  查询
                </el-button>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>

      <!-- 玩家数据展示 -->
      <el-row justify="center" style="margin-top: 40px;" v-if="playerData">
        <el-col :xs="24" :sm="20" :md="16" :lg="12">
          <el-card shadow="hover">
            <!-- 全球统计 -->
            <h2>玩家数据</h2>
            <el-descriptions :column="2" border>
          <el-descriptions-item label="名称">{{ playerData.bedwars.global_stats.name }}</el-descriptions-item>
            <el-descriptions-item label="首次游戏">{{ playerData.bedwars.global_stats.first_play }}</el-descriptions-item>
            <el-descriptions-item label="最后游戏">{{ playerData.bedwars.global_stats.last_play }}</el-descriptions-item>
            <el-descriptions-item label="胜利">{{ playerData.bedwars.global_stats.wins }}</el-descriptions-item>
            <el-descriptions-item label="击杀">{{ playerData.bedwars.global_stats.kills }}</el-descriptions-item>
            <el-descriptions-item label="最终击杀">{{ playerData.bedwars.global_stats.final_kills }}</el-descriptions-item>
            <el-descriptions-item label="失败">{{ playerData.bedwars.global_stats.looses }}</el-descriptions-item>
            <el-descriptions-item label="死亡">{{ playerData.bedwars.global_stats.deaths }}</el-descriptions-item>
            <el-descriptions-item label="最终死亡">{{ playerData.bedwars.global_stats.final_deaths }}</el-descriptions-item>
            <el-descriptions-item label="床被摧毁">{{ playerData.bedwars.global_stats.beds_destroyed }}</el-descriptions-item>
            <el-descriptions-item label="游戏次数">{{ playerData.bedwars.global_stats.games_played }}</el-descriptions-item>
          </el-descriptions>

            <el-divider></el-divider>

            <!-- 热键管理 -->
            <h2>热键管理</h2>
            <el-row :gutter="20" justify="center">
              <el-col
                v-for="item in playerData.bedwars.bedwars_hotbar_manager"
                :key="item.slot"
                :xs="24"
                :sm="12"
                :md="8"
                :lg="6"
              >
                <el-card shadow="hover" class="hotbar-card">
                  <div class="hotbar-content">
                    
                    <div class="hotbar-player"><strong>类型:</strong> {{ item.player }}</div>
                  </div>
                </el-card>
              </el-col>
            </el-row>

            <el-divider></el-divider>

            <!-- 连胜记录 -->
            <h2>连胜记录</h2>
            <el-descriptions :column="2" border>
              <el-descriptions-item label="UUID">{{ playerData.bedwars.bw1058_winstreak.uuid }}</el-descriptions-item>
              <el-descriptions-item label="当前连胜">{{ playerData.bedwars.bw1058_winstreak.current_streak }}</el-descriptions-item>
              <el-descriptions-item label="最高连胜">{{ playerData.bedwars.bw1058_winstreak.best_streak }}</el-descriptions-item>
            </el-descriptions>

          <el-divider></el-divider>

          <!-- 玩家等级 -->
          <h2>玩家等级</h2>
          <el-descriptions :column="2" border>
            <el-descriptions-item label="UUID">{{ playerData.bedwars.player_levels.uuid }}</el-descriptions-item>
            <el-descriptions-item label="等级">{{ playerData.bedwars.player_levels.level }}</el-descriptions-item>
            <el-descriptions-item label="经验">{{ playerData.bedwars.player_levels.xp }}</el-descriptions-item>
            <el-descriptions-item label="下一等级经验">{{ playerData.bedwars.player_levels.next_cost }}</el-descriptions-item>
            <el-descriptions-item label="星级">
              <span v-for="n in extractedStars" :key="n">
                <el-icon><Star /></el-icon>
              </span>
            </el-descriptions-item>
          </el-descriptions>
        </el-card>
      </el-col>
    </el-row>
  </el-main>

    <!-- 页脚 -->
    <el-footer>
      <div class="footer-content">
        <span>&copy; 2024 TryStage Minecraft Server. 保留所有权利。</span>
      </div>
    </el-footer>

    <el-backtop></el-backtop>
  </el-container>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import axios from 'axios'
import { ElMessage, ElLoading } from 'element-plus'
import { Star, Search, Sunny, Moon } from '@element-plus/icons-vue'

/** 定义接口以匹配 API 返回的数据结构 */
interface GlobalStats {
name: string
first_play: string
last_play: string
wins: string
kills: string
final_kills: string
looses: string
deaths: string
final_deaths: string
beds_destroyed: string
games_played: string
}

interface BedwarsHotbarManagerItem {
slot: string
player: string
}

interface Winstreak {
uuid: string
current_streak: string
best_streak: string
}

interface PlayerLevels {
uuid: string
level: string
xp: string
name: string
next_cost: string
}

interface BedwarsData {
global_stats: GlobalStats
bedwars_hotbar_manager: BedwarsHotbarManagerItem[]
bw1058_winstreak: Winstreak
player_levels: PlayerLevels
}

interface ApiResponse {
bedwars: BedwarsData
}

export default defineComponent({
name: 'App',
components: {
  Star,
  Search,
  Moon,
  Sunny
},
setup() {
  // 硬编码 API 基础 URL
  const API_BASE_URL = 'https://api.trystage.win/api'

  const playerName = ref<string>('')
  const playerData = ref<ApiResponse | null>(null)
  const isDark = ref<boolean>(false)
  const loading = ref<boolean>(false)

  /**
   * 获取玩家数据
   */
  const fetchPlayerData = async (): Promise<void> => {
    if (!playerName.value.trim()) {
      ElMessage.warning('请输入玩家名称')
      return
    }

    const url = `${API_BASE_URL}/player?name=${encodeURIComponent(playerName.value)}`
    console.log(`Fetching data from: ${url}`) // 调试信息

    loading.value = true
    const loadingInstance = ElLoading.service({
      lock: true,
      text: '加载中...',
      background: 'rgba(0, 0, 0, 0.7)',
    })

    try {
      const response = await axios.get<ApiResponse>(url)
      playerData.value = response.data
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          ElMessage.error('玩家未找到，请检查玩家名称')
        } else {
          ElMessage.error(`获取数据失败：${error.message}`)
        }
      } else {
        ElMessage.error('发生未知错误')
      }
      console.error(error)
    } finally {
      loadingInstance.close()
      loading.value = false
    }
  }

  /**
   * 切换主题
   */
  const toggleTheme = (): void => {
    // 主题切换逻辑已通过绑定 :class 实现，这里可以留空或进行其他操作
  }

  /**
   * 提取星级数
   */
  const extractedStars = computed<number>(() => {
    if (playerData.value && playerData.value.bedwars.player_levels.name) {
      const match = playerData.value.bedwars.player_levels.name.match(/\[(\d+)✩\]/)
      if (match && match[1]) {
        return Number(match[1])
      }
    }
    return 0
  })

  return {
    playerName,
    playerData,
    fetchPlayerData,
    isDark,
    toggleTheme,
    extractedStars,
    loading
  }
}
})
</script>

<style scoped>
:root {
  --background: #f5f7fa;
  --text-color: #333;
}

.dark-theme {
  --background: #2c3e50;
  --text-color: #ecf0f1;
}

.el-header, .el-footer {
  background-color: var(--background);
  color: var(--text-color);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.el-header h1 {
  margin: 0;
  font-size: 1.8em;
  color: var(--text-color);
}

.search-card {
  border-radius: 10px;
}

.el-card {
  background-color: var(--background);
  color: var(--text-color);
}

.el-descriptions__label, .el-descriptions__content {
  color: var(--text-color);
}

.el-footer {
  text-align: center;
  padding: 20px;
  background-color: var(--background);
  color: var(--text-color);
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.footer-content .el-link {
  color: var(--text-color);
}

.dark-theme .el-footer {
  border-top: 1px solid #7f8c8d;
}

body, html, #app {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Roboto', sans-serif;
}

.el-backtop {
  right: 40px;
}

@media (max-width: 768px) {
  .header-content h1 {
    font-size: 1.5em;
  }
  .footer-content {
    flex-direction: column;
    gap: 10px;
  }
}

/* 新增样式 */
.hotbar-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* 根据需要调整高度 */
}

.hotbar-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hotbar-slot,
.hotbar-player {
  margin: 5px 0;
  color: var(--text-color);
}
</style>
