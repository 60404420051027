import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withKeys as _withKeys, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "header-content" }
const _hoisted_2 = { class: "hotbar-content" }
const _hoisted_3 = { class: "hotbar-player" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_Sunny = _resolveComponent("Sunny")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_Moon = _resolveComponent("Moon")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_search = _resolveComponent("search")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_Star = _resolveComponent("Star")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_footer = _resolveComponent("el-footer")!
  const _component_el_backtop = _resolveComponent("el-backtop")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createBlock(_component_el_container, {
    class: _normalizeClass({ 'dark-theme': _ctx.isDark }),
    style: {"min-height":"100vh","background":"var(--background)","transition":"background 0.3s"}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_header, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_avatar, { src: "https://trystage.win/img.png" }),
            _createVNode(_component_el_text, {
              class: "mx-1",
              type: "primary",
              size: "large"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("TryStage 玩家信息查询")
              ])),
              _: 1
            }),
            _createVNode(_component_el_switch, {
              modelValue: _ctx.isDark,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isDark) = $event)),
              "active-action-icon": "Sunny",
              "inactive-action-icon": "Moon",
              onChange: _ctx.toggleTheme,
              "active-color": "#ffd04b",
              "inactive-color": "#409EFF"
            }, {
              prefix: _withCtx(() => [
                _createVNode(_component_el_icon, { class: "el-switch__icon" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Sunny)
                  ]),
                  _: 1
                })
              ]),
              suffix: _withCtx(() => [
                _createVNode(_component_el_icon, { class: "el-switch__icon" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Moon)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue", "onChange"])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, {
            justify: "center",
            style: {"margin-top":"40px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, {
                xs: 24,
                sm: 20,
                md: 16,
                lg: 12
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_card, {
                    class: "search-card",
                    shadow: "hover"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_row, {
                        gutter: 20,
                        align: "middle"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_col, { span: 18 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_input, {
                                modelValue: _ctx.playerName,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.playerName) = $event)),
                                placeholder: "请输入玩家名称",
                                onKeyup: _withKeys(_ctx.fetchPlayerData, ["enter"]),
                                clearable: ""
                              }, {
                                prefix: _withCtx(() => [
                                  _createVNode(_component_el_icon, { class: "el-input__icon" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_search)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }, 8, ["modelValue", "onKeyup"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_col, { span: 6 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_button, {
                                type: "primary",
                                onClick: _ctx.fetchPlayerData,
                                loading: _ctx.loading,
                                block: ""
                              }, {
                                default: _withCtx(() => _cache[3] || (_cache[3] = [
                                  _createTextVNode(" 查询 ")
                                ])),
                                _: 1
                              }, 8, ["onClick", "loading"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (_ctx.playerData)
            ? (_openBlock(), _createBlock(_component_el_row, {
                key: 0,
                justify: "center",
                style: {"margin-top":"40px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, {
                    xs: 24,
                    sm: 20,
                    md: 16,
                    lg: 12
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_card, { shadow: "hover" }, {
                        default: _withCtx(() => [
                          _cache[5] || (_cache[5] = _createElementVNode("h2", null, "玩家数据", -1)),
                          _createVNode(_component_el_descriptions, {
                            column: 2,
                            border: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_descriptions_item, { label: "名称" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.playerData.bedwars.global_stats.name), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_descriptions_item, { label: "首次游戏" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.playerData.bedwars.global_stats.first_play), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_descriptions_item, { label: "最后游戏" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.playerData.bedwars.global_stats.last_play), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_descriptions_item, { label: "胜利" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.playerData.bedwars.global_stats.wins), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_descriptions_item, { label: "击杀" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.playerData.bedwars.global_stats.kills), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_descriptions_item, { label: "最终击杀" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.playerData.bedwars.global_stats.final_kills), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_descriptions_item, { label: "失败" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.playerData.bedwars.global_stats.looses), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_descriptions_item, { label: "死亡" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.playerData.bedwars.global_stats.deaths), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_descriptions_item, { label: "最终死亡" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.playerData.bedwars.global_stats.final_deaths), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_descriptions_item, { label: "床被摧毁" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.playerData.bedwars.global_stats.beds_destroyed), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_descriptions_item, { label: "游戏次数" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.playerData.bedwars.global_stats.games_played), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_divider),
                          _cache[6] || (_cache[6] = _createElementVNode("h2", null, "热键管理", -1)),
                          _createVNode(_component_el_row, {
                            gutter: 20,
                            justify: "center"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.playerData.bedwars.bedwars_hotbar_manager, (item) => {
                                return (_openBlock(), _createBlock(_component_el_col, {
                                  key: item.slot,
                                  xs: 24,
                                  sm: 12,
                                  md: 8,
                                  lg: 6
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_card, {
                                      shadow: "hover",
                                      class: "hotbar-card"
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_2, [
                                          _createElementVNode("div", _hoisted_3, [
                                            _cache[4] || (_cache[4] = _createElementVNode("strong", null, "类型:", -1)),
                                            _createTextVNode(" " + _toDisplayString(item.player), 1)
                                          ])
                                        ])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_divider),
                          _cache[7] || (_cache[7] = _createElementVNode("h2", null, "连胜记录", -1)),
                          _createVNode(_component_el_descriptions, {
                            column: 2,
                            border: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_descriptions_item, { label: "UUID" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.playerData.bedwars.bw1058_winstreak.uuid), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_descriptions_item, { label: "当前连胜" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.playerData.bedwars.bw1058_winstreak.current_streak), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_descriptions_item, { label: "最高连胜" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.playerData.bedwars.bw1058_winstreak.best_streak), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_divider),
                          _cache[8] || (_cache[8] = _createElementVNode("h2", null, "玩家等级", -1)),
                          _createVNode(_component_el_descriptions, {
                            column: 2,
                            border: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_descriptions_item, { label: "UUID" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.playerData.bedwars.player_levels.uuid), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_descriptions_item, { label: "等级" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.playerData.bedwars.player_levels.level), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_descriptions_item, { label: "经验" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.playerData.bedwars.player_levels.xp), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_descriptions_item, { label: "下一等级经验" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.playerData.bedwars.player_levels.next_cost), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_descriptions_item, { label: "星级" }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.extractedStars, (n) => {
                                    return (_openBlock(), _createElementBlock("span", { key: n }, [
                                      _createVNode(_component_el_icon, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_Star)
                                        ]),
                                        _: 1
                                      })
                                    ]))
                                  }), 128))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_el_footer, null, {
        default: _withCtx(() => _cache[9] || (_cache[9] = [
          _createElementVNode("div", { class: "footer-content" }, [
            _createElementVNode("span", null, "© 2024 TryStage Minecraft Server. 保留所有权利。")
          ], -1)
        ])),
        _: 1
      }),
      _createVNode(_component_el_backtop)
    ]),
    _: 1
  }, 8, ["class"]))
}